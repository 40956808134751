<template>
  <v-layout align-center justify-center>
    <v-flex xs12 sm8 md4>
      <v-card max-width="720" class="mx-auto pb-10">
        <v-card-text>
          <transition name="fade">
            <div v-if="!billingDone">
              <v-container class="pa-8">
                <div class="text-h5">Signup</div>
                <div class="mt-4">
                  <label>Step {{ signupCurrenTab + 1 }} of 3</label>
                  <v-progress-linear
                    rounded
                    color="primary"
                    :value="progressValue"
                  ></v-progress-linear>
                </div>
              </v-container>
              <div class="px-4">
                <v-tabs class="no-tab-bar">
                  <v-tabs-items v-model="currentTab">
                    <v-tab-item value="user-details-tab">
                      <UserForm />
                    </v-tab-item>
                    <v-tab-item value="organisation-details-tab">
                      <OrganisationForm />
                    </v-tab-item>
                    <v-tab-item value="billing-details-tab">
                      <BillingForm @done="onBillingDone" />
                    </v-tab-item>
                  </v-tabs-items>
                </v-tabs>
              </div>
            </div>
            <div v-else>
              <v-container class="pa-10">
                <v-row align="center">
                  <div>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Vestibulum suscipit, mi in dignissim tristique, lorem dolor
                    lobortis diam, a lobortis urna sapien a urna. Sed molestie
                    nulla at iaculis venenatis. Nam non fringilla diam. Ut in
                    justo nec sapien dignissim faucibus vel vitae nunc. Morbi
                    nunc nisi, tempus non fringilla ac, consequat eget neque.
                    Aliquam nec tempus quam. Morbi a quam tincidunt massa
                    placerat dignissim. Nunc varius sed felis ut egestas.
                  </div>
                </v-row>
                <br />
                <v-btn large block outlined @loading="isLoading" @click="join">
                  Join Now!
                </v-btn>
              </v-container>
            </div>
          </transition>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import UserForm from './components/UserForm'
import OrganisationForm from './components/OrganisationForm'
import BillingForm from './components/BillingForm'
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'SignupPage',

  components: {
    UserForm,
    OrganisationForm,
    BillingForm
  },

  data() {
    return {
      billingDone: false,
      isLoading: false
    }
  },

  computed: {
    ...mapState({
      signupCurrenTab: state => state.controls.signupCurrenTab
    }),

    currentTab() {
      if (this.signupCurrenTab === 1) {
        return 'organisation-details-tab'
      } else if (this.signupCurrenTab === 2) {
        return 'billing-details-tab'
      } else {
        return 'user-details-tab'
      }
    },

    progressValue() {
      return (this.signupCurrenTab + 1) * 33.33
    }
  },

  created() {
    this.initiateSignUpTab()
  },

  methods: {
    ...mapMutations({
      initiateSignUpTab: 'initiateSignUpTab'
    }),

    onBillingDone(val) {
      this.billingDone = val
    },

    join() {
      this.isLoading = true
      this.$api.post('/signup/join').then(({ data }) => {
        this.$router.replace({ name: 'users' })
        this.isLoading = false
      })
    }
  }
}
</script>

<style></style>
