<template>
  <div>
    <v-card outlined class="ma-4" v-if="orgSize">
      <v-card-text>
        <label class="text-subtitle-1"> Payment Charges </label>
        <v-divider class="py-2" />
        <v-simple-table>
          <tr>
            <td>Quantity</td>
            <td align="right">{{ orgSize }}</td>
          </tr>
          <tr>
            <td>Price per item</td>
            <td align="right">AUD {{ moneyFormat(pricePerItem) }}</td>
          </tr>
          <tr>
            <td class="pt-2 text-subtitle-1">Total</td>
            <td align="right" class="pt-2 text-subtitle-1">
              AUD {{ moneyFormat(totalCharge) }}
            </td>
          </tr>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <v-card flat>
      <v-card-title> Payment Information </v-card-title>
      <v-card-text>
        <label class="text-field-label">Email</label>
        <v-text-field
          outlined
          type="email"
          v-model="details.email"
        ></v-text-field>

        <label class="text-field-label">Name on card</label>
        <v-text-field outlined v-model="details.name"></v-text-field>

        <label class="text-field-label">Country</label>
        <v-autocomplete
          outlined
          item-text="name"
          item-value="iso2"
          :items="countries"
          v-model="details.country"
        ></v-autocomplete>

        <label class="text-field-label">Card information</label>
        <div id="card-element"></div>
        <div
          v-show="cardError"
          class="v-messages theme--light error--text pa-2"
          role="alert"
        >
          <div class="v-messages__wrapper">
            <div class="v-messages__message">{{ cardError }}</div>
          </div>
        </div>
        <v-btn
          class="mt-10"
          large
          block
          outlined
          @click="submit"
          :disabled="disableSubmit"
          :loading="isLoading"
          >Submit</v-btn
        >
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import env from '@/services/env'
import { moneyFormat } from '@/utils/helpers'

let stripe = null
let elements = null
let cardElement = null

export default {
  data() {
    return {
      countries: [],
      token: null,
      paymentIntent: {},
      details: {
        email: '',
        name: '',
        country: 'AU',
      },
      cardComplete: false,
      cardError: null,
      isLoading: false,
      orgSize: 0,
    }
  },

  mounted() {
    this.getOrgSize()
    this.fetchCountries()
    this.fetchPaymentIntent().then(() => {
      stripe = window.Stripe(env.stripePublishableKey)
      elements = stripe.elements({
        clientSecret: this.paymentIntent.client_secret,
      })
      cardElement = elements.create('card')
      cardElement.mount('#card-element')
      cardElement.on('change', (event) => {
        if (event.complete) {
          this.cardComplete = true
          this.cardError = null
        } else if (event.error) {
          this.cardComplete = false
          this.cardError = event.error.message
        } else {
          this.cardComplete = false
          this.cardError = null
        }
      })
    })
  },

  computed: {
    disableSubmit() {
      return (
        !this.details.email ||
        !this.details.name ||
        !this.details.country ||
        !this.cardComplete
      )
    },
    pricePerItem() {
      if (this.orgSize > 50) {
        return 8
      } else if (this.orgSize > 10) {
        return 9
      } else {
        return 10
      }
    },
    totalCharge() {
      return this.orgSize * this.pricePerItem
    },
  },

  methods: {
    moneyFormat(val) {
      return moneyFormat(val)
    },

    async fetchCountries() {
      const res = await this.$api.get('/countries')
      this.countries = res.data.data
    },

    async fetchPaymentIntent() {
      const { data } = await this.$api.post('/signup/payment/setup-intent')
      this.paymentIntent = data.data.intent
      this.details.email = data.data.email
      this.details.name = data.data.name
    },

    async submit() {
      if (this.disableSubmit) return

      this.isLoading = true
      const res = await stripe.confirmCardSetup(
        this.paymentIntent.client_secret,
        {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: this.details.name,
              email: this.details.email,
              address: { country: this.details.country },
            },
          },
        }
      )
      if (res.error) {
        this.cardError = res.error.message
      } else {
        await this.$api
          .post('/signup/payment/add-payment-method', {
            payment_method: res.setupIntent.payment_method,
          })
          .then(({ data }) => {
            this.$emit('done', true)
          })
      }

      this.isLoading = false
    },

    getOrgSize() {
      const orgSize = window.localStorage.getItem('organisation_user_size')
      if (orgSize) {
        this.orgSize = Number(orgSize)
      }
    },
  },
}
</script>

<style>
.payment-page {
  /* height: 100vh; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.payment-card {
  padding: 20px;
  width: 100%;
  max-width: 500px;
}
.StripeElement {
  border: #000 solid 1px;
  padding: 18px;
  border-radius: 4px;
}
.StripeElement--focus {
  border: #000 solid 2px;
}
.StripeElement--invalid {
  border: #fa4856 solid 2px;
}
.mt-50 {
  margin-top: 50px;
}
</style>
