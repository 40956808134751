<template>
  <div class="user-card">
    <v-form ref="form" v-model="isFormValid" @submit.prevent="submit">
      <v-card flat>
        <v-card-title>Personal Details</v-card-title>
        <v-card-text>
          <v-container>
            <v-row dense>
              <v-col>
                <label class="text-field-label">First Name</label>
                <v-text-field
                  outlined
                  v-model="form.first_name"
                  :error-messages="form.$getError('first_name')"
                ></v-text-field
              ></v-col>
              <v-col>
                <label class="text-field-label">Last Name</label>
                <v-text-field
                  outlined
                  v-model="form.last_name"
                  :error-messages="form.$getError('last_name')"
                ></v-text-field
              ></v-col>
            </v-row>

            <label class="text-field-label">Job Title</label>
            <v-text-field
              outlined
              v-model="form.job_title"
              :error-messages="form.$getError('job_title')"
            ></v-text-field>

            <v-row dense>
              <v-col
                ><label class="text-field-label">Password</label>
                <v-text-field
                  outlined
                  v-model="form.password"
                  :type="showPassword ? 'text' : 'password'"
                  :error-messages="form.$getError('password')"
                  :rules="[rules.password_min]"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword = !showPassword"
                ></v-text-field
              ></v-col>
              <v-col>
                <label class="text-field-label">Confirm Password</label>
                <v-text-field
                  outlined
                  v-model="form.password_confirmation"
                  :type="showPasswordConfirmation ? 'text' : 'password'"
                  :error-messages="form.$getError('password_confirmation')"
                  :rules="[rules.password_confirmed]"
                  :append-icon="
                    showPasswordConfirmation ? 'mdi-eye' : 'mdi-eye-off'
                  "
                  @click:append="
                    showPasswordConfirmation = !showPasswordConfirmation
                  "
                ></v-text-field>
              </v-col>
            </v-row>

            <label class="text-field-label">Email</label>
            <v-row dense>
              <v-col cols="8">
                <v-text-field
                  outlined
                  v-model="form.email"
                  type="email"
                  :error-messages="form.$getError('email')"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-btn
                  height="56px"
                  block
                  outlined
                  @click="sendOTP"
                  :loading="isLoading"
                  :disabled="!disableSendOTP"
                  >Send OTP</v-btn
                >
              </v-col>
            </v-row>
            <label class="text-field-label">OTP</label>
            <v-text-field
              outlined
              v-model="form.otp"
              required
              :error-messages="form.$getError('otp')"
            ></v-text-field>
            <br />
            <v-btn
              large
              block
              outlined
              :disabled="disableSubmit"
              type="submit"
              :loading="form.$busy"
              >Next</v-btn
            >
          </v-container>
        </v-card-text>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import Form from '@/utils/form'

export default {
  data() {
    return {
      otpSent: false,
      isFormValid: false,
      isLoading: false,
      form: new Form({
        first_name: '',
        last_name: '',
        job_title: '',
        password: '',
        password_confirmation: '',
        email: '',
        otp: ''
      }),
      showPassword: false,
      showPasswordConfirmation: false,
      rules: {
        password_confirmed: () =>
          this.passwordConfirmation || 'Password must match',
        password_min: () =>
          this.password8Min || 'Password must be minimum of 8 character'
      }
    }
  },

  computed: {
    disableSubmit() {
      return (
        !this.otpSent ||
        !this.form.first_name ||
        !this.form.last_name ||
        !this.form.job_title ||
        !this.password8Min ||
        !this.passwordConfirmation ||
        !this.form.email ||
        !this.form.otp
      )
    },

    disableSendOTP() {
      return String(this.form.email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    },

    passwordConfirmation() {
      return this.form.password === this.form.password_confirmation
    },

    password8Min() {
      return this.form.password.length >= 8
    }
  },

  methods: {
    ...mapMutations({
      signupNextTab: 'signupNextTab'
    }),

    async sendOTP() {
      this.isLoading = true
      this.otpSent = false
      this.form.$clearError('email')
      this.$api
        .post('/signup/verification', { email: this.form.email })
        .then(({ data }) => {
          this.isLoading = false
          this.otpSent = true
        })
        .catch(err => {
          this.isLoading = false
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
    },

    async submit() {
      this.form.$clearErrors()
      this.form.$busy = true
      this.$api
        .post('/signup/user', {
          first_name: this.form.first_name,
          last_name: this.form.last_name,
          job_title: this.form.job_title,
          password: this.form.password,
          password_confirmation: this.form.password_confirmation,
          email: this.form.email,
          otp: this.form.otp
        })
        .then(({ data }) => {
          this.form.$busy = false
          this.$store.commit('auth/setToken', data.data)
          this.signupNextTab()
        })
        .catch(err => {
          this.form.$busy = false
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
    }
  }
}
</script>

<style></style>
