<template>
  <v-form ref="form" v-model="isFormValid" @submit.prevent="submit">
    <v-card flat>
      <v-card-title> Organisation Details </v-card-title>
      <v-card-text>
        <upload-icon :image-source="null" @change="onChangeImage" />
        <div
          class="ml-5 v-messages theme--light error--text"
          v-if="form.$getError('icon')"
        ></div>
        <br />
        <label class="text-field-label">Organisation Name</label>
        <v-text-field
          outlined
          v-model="form.name"
          required
          :error-messages="form.$getError('name')"
        ></v-text-field>
        <label class="text-field-label">Industry</label>
        <v-text-field
          outlined
          v-model="form.industry"
          required
          :error-messages="form.$getError('industry')"
        ></v-text-field>
        <label class="text-field-label">Website URL</label>
        <v-text-field
          outlined
          v-model="form.website_url"
          prefix="https://"
          id="website-field"
          class="website-field"
          required
          :error-messages="form.$getError('website_url')"
        ></v-text-field>
        <br />
        <v-btn
          large
          block
          outlined
          :disabled="disableSubmit"
          type="submit"
          :loading="form.$busy"
          >Next</v-btn
        >
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import { mapMutations } from 'vuex'
import Form from '@/utils/form'
import Compressor from 'compressorjs'
import UploadIcon from './UploadIcon'

export default {
  components: {
    UploadIcon
  },

  data() {
    return {
      isFormValid: false,
      isLoading: false,
      form: new Form({
        name: '',
        industry: '',
        website_url: '',
        icon: ''
      })
    }
  },

  computed: {
    disableSubmit() {
      return (
        !this.form.name ||
        !this.form.industry ||
        !this.form.website_url ||
        !this.form.icon
      )
    }
  },

  methods: {
    ...mapMutations({
      signupNextTab: 'signupNextTab'
    }),

    onChangeImage(blob) {
      this.form.$clearErrors()

      if (blob.size < 4096) {
        this.form.icon = blob
      } else if (blob.size < 1000000) {
        let _this = this
        // eslint-disable-next-line
        new Compressor(blob, {
          quality: 0.2,
          success(result) {
            _this.form.icon = result
          }
        })
      } else {
        this.form.$setErrors({ icon: ['Image size is too large'] })
      }
    },

    async submit() {
      this.form.$clearErrors()
      this.form.$busy = true

      let formData = this.getFormData()

      this.$api
        .post('/signup/organisation', formData)
        .then(({ data }) => {
          this.form.$busy = false
          this.signupNextTab()
        })
        .catch(err => {
          this.form.$busy = false
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
    },

    getFormData() {
      let data = new FormData()

      data.append('icon', this.form.icon)
      data.append('name', this.form.name)
      data.append('industry', this.form.industry)
      data.append('website_url', 'https://'.concat(this.form.website_url))

      return data
    }
  }
}
</script>

<style>
.website-field div.v-text-field__prefix {
  padding: 0 !important;
}

input#website-field {
  padding: 8px 0 !important;
}
</style>
